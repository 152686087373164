<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Restrict Page</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleRestrictPage" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Pages</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.pages }">
                                    <Field autocomplete="off" name="pages" v-model="form.page_id" rules="required">
                                        <multiselect
                                            v-model="form.page_id"
                                            label="title"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="pages"
                                            placeholder="Select pages"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="pages" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.page_id.length">
                            <schedule-component :schedule-data="form" title="page restrict" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="pageLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="pageLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="pageLoader"></i> {{ pageLoader ? 'Restricting' : 'Restrict' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'Restrict Page',

        data () {
            return {
                form: {
                    contacts: '',
                    page_id: [],
                    action: '/contacts/restrict-page',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.pages.length == 0) {
                    vm.getAllPages();
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent
        },

        computed: {
            ...mapState({
                pages: state => state.pageModule.allPages,
                pageLoader: state => state.pageModule.pageLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllPages: 'pageModule/getAllPages',
                restrictPage: 'pageModule/restrictPage',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                              contacts: '',
                              page_id: [],
                              action: '/contacts/restrict-page',
                          };
            },

            handleRestrictPage (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.restrictPage(vm.form);
            },
        },
    }
</script>
